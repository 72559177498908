import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneNumberCommunicationModal from "../phoneNumberCommunicationModal/PhoneNumberCommunicationModal";
import { getCustomers } from "../../services/customers/services";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import ImportCustomer from "../importCustomer/ImportCustomer";
import EmailCommunicationModal from "../emailCommunicationModal.jsx/EmailCommunicationModal";
import NoResults from "../noResults/noResults";

const TableClients = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState([]);
  // track page number
  const [page, setPage] = useState(1);
  // total number of records
  const [pageNumber, setPageNumber] = useState(0);
  // track API response time
  const [isLoading, setIsLoading] = useState(false);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [categoryText] = useState({
    gd: "Good",
    bl: "Black list",
    cd: "Cold",
  });
  const [importModal, setImportModal] = useState(false);

  const toggleImportCustomers = () => {
    setImportModal((prevValue) => !prevValue);
  };

  const viewClient = (clientId) => {
    return navigate(`/clients/${clientId}`);
  };

  const handleOnChangeCategory = (event) => {
    const value = event.target.value;
    setCategory(value);
    setPage(1);
  };

  const handleOnChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
    setPage(1);
  };

  const handleNewCustomer = () => {
    return navigate(`/clients/new`);
  };

  const getClients = React.useCallback(async () => {
    setIsLoading(true);

    const data = await getCustomers(
      user,
      company.id,
      page,
      search,
      category,
      "all",
      5
    );
    setIsLoading(false);
    setClientsData(data?.customers ?? []);
    const count = data?.totalCustomers ?? 0;
    setTotalCustomers(count);
    setPageNumber(Math.ceil(count / 5));
  }, [category, company.id, page, search, user]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="grid gap-6 mb-6 md:grid-cols-3  mt-2 mr-2 ml-2">
        <div>
          <label
            htmlFor="category"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Filter by category
          </label>
          <select
            id="category"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            defaultValue={"all"}
            onChange={handleOnChangeCategory}
          >
            <option value="all">All</option>
            <option value="gd">Good</option>
            <option value="cd">Cold</option>
            <option value="bl">Black list</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="search"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Search
          </label>
          <input
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..."
            onChange={handleOnChangeSearch}
          />
        </div>
        <div className="flex justify-end items-end">
          <button
            type="button"
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={handleNewCustomer}
          >
            New Customer
          </button>
          <button
            type="button"
            className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={toggleImportCustomers}
          >
            Import
          </button>
        </div>
      </div>

      {!isLoading && clientsData.length === 0 && (
        <NoResults message="No clients found" />
      )}
      {clientsData.length === 0 && isLoading && <Loading />}
      {clientsData.length > 0 && (
        <>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Account number
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Contact
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                clientsData?.map((client) => {
                  return (
                    <tr
                      key={client?.accountNumber}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {client?.accountNumber}
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {client?.fullName}
                      </th>
                      <td className="px-6 py-4">
                        {categoryText[client?.category]}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-col">
                          {client?.phoneNumber && (
                            <PhoneNumberCommunicationModal
                              key={"phoneNumberModal"}
                              customer={client}
                              disabled={client?.category === "bl"}
                            />
                          )}
                          {client?.email && (
                            <EmailCommunicationModal
                              key="emailModal"
                              customer={client}
                              disabled={client?.category === "bl"}
                            />
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right">
                        <button
                          type="button"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          onClick={() => {
                            viewClient(client?._id);
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {isLoading && <Loading />}
          {!isLoading && (
            <Pagination
              key={"paginationCustomer"}
              data={clientsData}
              page={page}
              pageNumber={pageNumber}
              isLoading={isLoading}
              setPage={setPage}
              totalRecords={totalCustomers}
            />
          )}
        </>
      )}
      <ImportCustomer
        key={"importCustomerModal"}
        modalOpen={importModal}
        toggleModal={toggleImportCustomers}
        loadCustomer={getClients}
      />
    </div>
  );
};

export default TableClients;
