import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoResults from "../noResults/noResults";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import { getDepositsByPagination } from "../../services/deposit/services";
import DepositTypeModal from "../depositTypeModal/DepositTypeModal";

const DepositTypesTable = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  const [depositData, setDepositData] = useState([]);
  // track page number
  const [page, setPage] = useState(1);
  // total number of records
  const [pageNumber, setPageNumber] = useState(0);
  // track API response time
  const [isLoading, setIsLoading] = useState(false);
  const [totalDepositsTypes, setTotalDepositsTypes] = useState(0);
  const [search, setSearch] = useState("");

  const handleOnChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
    setPage(1);
  };

  const getDepositTypes = React.useCallback(async () => {
    setIsLoading(true);

    const data = await getDepositsByPagination(
      user,
      company.id,
      search,
      page,
      5
    );

    setIsLoading(false);
    setDepositData(data?.deposits ?? []);
    const count = data?.totalRecords ?? 0;
    setTotalDepositsTypes(count);
    setPageNumber(Math.ceil(count / 5));
  }, [company.id, page, search, user]);

  useEffect(() => {
    getDepositTypes();
  }, [getDepositTypes]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="grid gap-6 mb-6 md:grid-cols-2  mt-2 mr-2 ml-2">
        <div>
          <label
            htmlFor="search"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Search
          </label>
          <input
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..."
            onChange={handleOnChangeSearch}
          />
        </div>
        <div className="flex justify-end items-end">
          <DepositTypeModal
            key={"new"}
            deposit={{ _id: "new", name: "" }}
            getDepositTypes={getDepositTypes}
            text={"New Deposit"}
            cssButton={
              "text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            }
          />
        </div>
      </div>

      {!isLoading && depositData.length === 0 && (
        <NoResults message="No deposit type found" />
      )}
      {depositData.length === 0 && isLoading && <Loading />}
      {depositData.length > 0 && (
        <>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  State
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                depositData?.map((depositType) => {
                  return (
                    <tr
                      key={depositType?._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {depositType?.name}
                      </th>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {depositType?.status ? "Active" : "Deactivate"}
                      </th>
                      <td className="px-6 py-4 text-right">
                        <DepositTypeModal
                          key={depositType._id}
                          deposit={depositType}
                          getDepositTypes={getDepositTypes}
                          text={"Edit"}
                          cssButton={
                            "font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {isLoading && <Loading />}
          {!isLoading && (
            <Pagination
              key={"paginationDepositTypes"}
              data={depositData}
              page={page}
              pageNumber={pageNumber}
              isLoading={isLoading}
              setPage={setPage}
              totalRecords={totalDepositsTypes}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DepositTypesTable;
