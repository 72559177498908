import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";
import { encryptText } from "../../utils/encryptText/encryptText";

export const getUserById = async (user, userId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}user/getUsersById?&userId=${userId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const getUsersByCompany = async (user, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}user/getUsersByCompany?companyId=${companyId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const getUserNoteam = async (
  user,
  companyId,
  teamId,
  search,
  page,
  pageSize
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}user/getUserNoteam?companyId=${companyId}&teamId=${teamId}&search=${search}&page=${page}&pageSize=${pageSize}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const updateUser = async (user, userId, userData) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      userId,
      updateFields: userData,
    }),
  };

  const response = await fetch(`${baseApi}user/updateUser`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const addTeamUser = async (user, userId, teamId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      userId,
      teamId,
    }),
  };

  const response = await fetch(`${baseApi}user/addTeamUser`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const newPassword = async (email, currentPassword, newPassword) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const hashedCurrentPassword = encryptText(currentPassword);
  const hashedNewPassword = encryptText(newPassword);

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
    },
    body: JSON.stringify({
      email,
      currentPassword: hashedCurrentPassword,
      newPassword: hashedNewPassword,
    }),
  };

  const response = await fetch(
    `${baseApi}forgetPassword/newPassword`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const newUser = async (user, data) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({ data }),
  };

  const response = await fetch(`${baseApi}user/newUser`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};
