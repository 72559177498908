import React from "react";

const NoResults = ({ message }) => {
  return (
    <div className="p-4 text-center text-gray-500 dark:text-gray-400">
      {message}
    </div>
  );
};

export default NoResults;
