export function sortByText(arr) {
  return arr.sort((a, b) => {
    // "All" should always come first
    if (a.text === "All") {
      return -1;
    }
    if (b.text === "All") {
      return 1;
    }
    // Sort other elements alphabetically
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  });
}
