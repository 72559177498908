import CryptoJS from "crypto-js";

const KEY = process.env.REACT_APP_PASSWORD;

export function encryptText(text) {
  const encrypted = CryptoJS.AES.encrypt(text, KEY).toString();
  return encrypted;
}

// Function to decrypt text using AES decryption
export function decryptText(encryptedData) {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, KEY).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
}
