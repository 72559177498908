import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import { getTeamById, getTeamMembers } from "../../services/teams/services";
import NoResults from "../noResults/noResults";
import InviteUserModal from "../inviteUserModal/InviteUserModal";

const TableTeam = ({ teamId }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [employeeData, setEmployee] = useState([]);
  // track page number
  const [page, setPage] = useState(1);
  // total number of records
  const [pageNumber, setPageNumber] = useState(0);
  // track API response time
  const [isLoading, setIsLoading] = useState(false);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [search, setSearch] = useState("");
  const [team, setTeam] = useState({
    _id: "",
    name: "",
    companyId: "",
  });

  const viewEmployee = (employeeId) => {
    return navigate(`/team/${teamId}/${employeeId}`);
  };

  const handleBack = () => {
    return navigate(-1);
  };

  const handleOnChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
    setPage(1);
  };

  const handleNewTeamMember = () => {
    return navigate(`/team/${teamId}/new`);
  };

  const getTeamMembersData = React.useCallback(async () => {
    setIsLoading(true);

    const data = await getTeamMembers(
      user,
      teamId,
      company.id,
      page,
      5,
      search
    );

    setIsLoading(false);
    setEmployee(data?.teams ?? []);
    const count = data?.totalTeams ?? 0;
    setTotalEmployee(count);
    setPageNumber(Math.ceil(count / 5));
  }, [company.id, page, search, teamId, user]);

  const getTeamByIdData = React.useCallback(async () => {
    setIsLoading(true);

    const data = await getTeamById(user, teamId);
    setTeam(data?.team);
  }, [teamId, user]);

  useEffect(() => {
    getTeamByIdData();
  }, [getTeamByIdData]);

  useEffect(() => {
    getTeamMembersData();
  }, [getTeamMembersData]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex justify-start mt-2 ml-2">
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rotate-180"
          onClick={handleBack}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Icon description</span>
        </button>
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mt-2 ml-2">
          {team.name}
        </h3>
      </div>
      <div className="grid gap-6 mb-6 md:grid-cols-2 grid-cols-2 mt-2 mr-2 ml-2">
        <div>
          <label
            htmlFor="search"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Search
          </label>
          <input
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..."
            onChange={handleOnChangeSearch}
          />
        </div>

        <div className="flex justify-end items-end gap-4">
          <button
            type="button"
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={handleNewTeamMember}
          >
            New Member
          </button>
          <InviteUserModal
            key={"inviteUser"}
            teamId={teamId}
            loadTeamMembers={getTeamMembersData}
          />
        </div>
      </div>

      {!isLoading && employeeData.length === 0 && (
        <NoResults message="No members found" />
      )}
      {employeeData.length === 0 && isLoading && <Loading />}
      {employeeData.length > 0 && (
        <>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employeeData.map((employee) => {
                return (
                  <tr
                    key={employee._id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {employee.firstName + " " + employee.lastName}
                    </th>
                    <td className="px-6 py-4">{employee.permissions}</td>
                    <td className="px-6 py-4">
                      {employee.status ? "Active" : "Deactivate"}
                    </td>
                    <td className="px-6 py-4 text-right">
                      <button
                        type="button"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => {
                          viewEmployee(employee._id);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading && <Loading />}
          {!isLoading && (
            <Pagination
              key={"paginationCustomer"}
              data={employeeData}
              page={page}
              pageNumber={pageNumber}
              isLoading={isLoading}
              setPage={setPage}
              totalRecords={totalEmployee}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TableTeam;
