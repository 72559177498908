import { createSlice } from "@reduxjs/toolkit";

const storage = localStorage.getItem("company");

const initialState = (storage ? JSON.parse(storage).id : undefined)
  ? {
      company: JSON.parse(storage),
      isLoading: false,
    }
  : {
      company: {
        id: "",
        name: "",
      },
      isLoading: false,
    };

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stoptLoading: (state) => {
      state.isLoading = false;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
      state.isLogged = true;
    },
    singOutCompany: (state) => {
      state.company = {};
      localStorage.setItem("company", JSON.stringify({}));
      state.isLogged = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, startLoading, stoptLoading, singOutCompany } =
  companySlice.actions;
