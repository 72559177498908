import React, { useEffect, useState } from "react";
import {
  messageIcons,
  mailIcon,
  phoneIcon,
  depositIcon,
  commentIcon,
  clockIcon,
  chainIcon,
  newUserIcon,
} from "../../utils/historyIcons/historyIcons";
import { convertSeconds } from "../../utils/parse/parse";
import { getAllDepositsByCompany } from "../../services/deposit/services";
import { useSelector } from "react-redux";

const HistoryCard = ({ histories }) => {
  const { user } = useSelector((state) => state.user);

  const [types] = useState({
    EMAIL: "Email",
    SMS: "Message",
    CL: "Call",
    DP: "Deposit",
    CMM: "Comment",
    LG: "Login",
    RESET: "Reset Password",
    NEWUSER: "New user",
  });

  const [icons] = useState({
    EMAIL: mailIcon,
    SMS: messageIcons,
    CL: phoneIcon,
    DP: depositIcon,
    CMM: commentIcon,
    LG: clockIcon,
    RESET: chainIcon(),
    NEWUSER: newUserIcon,
  });
  const [depositTypes, setDepositTypes] = useState([]);

  const getText = (history) => {
    const text = {
      SMS: history.message,
      EMAIL: history.html,
      CL:
        "Voice Call " +
        convertSeconds(history.duration) +
        " Customer: " +
        history.customer[0]?.firstName +
        " " +
        history.customer[0]?.lastName,
      DP: history.comment,
      CMM: history.comment,
      LG: history.comment,
      RESET: "Reset Password",
      NEWUSER: history.html,
    };

    return text[history?.type] || "";
  };

  const getDepositTypeName = (depositCode) => {
    return depositTypes.find((depositType) => depositType.code === depositCode)
      ?.name;
  };

  const getDepositTypesCall = React.useCallback(async () => {
    const data = await getAllDepositsByCompany(user, user.companyId);
    setDepositTypes(data.deposits);
  }, [user]);

  useEffect(() => {
    getDepositTypesCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(histories);
  return (
    <>
      {histories?.map((his) => (
        <div key={his._id} className="flex items-start gap-2.5">
          {icons[his.type]}

          <div className="flex flex-col w-full leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="text-sm font-semibold text-gray-900 dark:text-white">
                {his.user?.firstName} {his.user?.lastName}
              </span>
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {types[his.type]}
              </span>
            </div>
            {his.type !== "EMAIL" &&
              his.type !== "NEWUSER" &&
              his.type !== "DP" && (
                <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                  {getText(his)}
                </p>
              )}
            {his.type === "DP" && (
              <>
                <div className="flex justify-start gap-12">
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Customer:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {his.customer[0]?.firstName} {his.customer[0]?.lastName}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Deposit Type:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {getDepositTypeName(his.depositType)}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Amount:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {his.amount}
                      </span>
                    </span>
                  </div>
                </div>
                <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                  {getText(his)}
                </p>
              </>
            )}

            {(his.type === "EMAIL" || his.type === "NEWUSER") && (
              <>
                <div className="flex justify-start gap-12">
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Customer:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {his?.customer[0]?.firstName}{" "}
                        {his?.customer[0]?.lastName}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Email:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {his?.customer[0]?.email}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-bold py-2.5 text-gray-900 dark:text-white">
                      Template Name:{" "}
                      <span className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {his?.templateName}
                      </span>
                    </span>
                  </div>
                </div>
              </>
            )}

            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {new Date(his.dateTime).toLocaleDateString()} -{" "}
              {new Date(his.dateTime).toLocaleTimeString()}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default HistoryCard;
