import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNotifications } from "../../context/Notifications";
import { addTeamUser, getUserNoteam } from "../../services/user/services";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import NoResults from "../noResults/noResults";

function InviteUserModal({ teamId, loadTeamMembers }) {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const { addNotification } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  // track page number
  const [page, setPage] = useState(1);
  // total number of records
  const [pageNumber, setPageNumber] = useState(0);
  // track API response time
  const [totalTeams, setTotalTeams] = useState(0);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onChangeUserSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleAddUser = async (event, userSelected) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const response = await addTeamUser(user, userSelected._id, teamId);

    if (response?.success) {
      addNotification({
        id: Date.now(),
        message: "User added!",
        kind: "success",
      });

      await getUsers();
      await loadTeamMembers();
      setIsLoading(false);
    } else {
      addNotification({
        id: Date.now(),
        message: "Error to add the user!",
        kind: "danger",
      });
    }
    setIsLoading(false);
  };

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    const data = await getUserNoteam(user, company.id, teamId, search, page, 5);

    setIsLoading(false);
    setUsers(data.users);
    const count = data?.totalRecords ?? 0;
    setTotalTeams(count);
    setPageNumber(Math.ceil(count / 5));
  }, [company.id, search, teamId, user, page]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <button
        type="button"
        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        onClick={toggleModal}
      >
        Invite User
      </button>

      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Invite User
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <form method="POST">
                  <div className="grid gap-6 mb-6 md:grid-cols-1">
                    <div className="grid gap-6 mb-6 md:grid-cols-2 mt-2 mr-2 ml-2">
                      <div>
                        <label
                          htmlFor="search"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Search
                        </label>
                        <input
                          type="text"
                          id="search"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Search..."
                          onChange={onChangeUserSearch}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center">
                      {!isLoading && users.length === 0 && (
                        <NoResults message="No User found" />
                      )}
                      {!isLoading && users.length > 0 && (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {users?.map((team) => {
                              return (
                                <tr
                                  key={team._id}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {team?.firstName} {team?.lastName}
                                  </th>
                                  <td className="px-6 py-4 text-right">
                                    <button
                                      type="button"
                                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                      disabled={isLoading}
                                      onClick={(event) => {
                                        handleAddUser(event, team);
                                      }}
                                    >
                                      Invite
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                      {isLoading && <Loading />}
                      {!isLoading && (
                        <Pagination
                          key={"paginationTeams"}
                          data={users}
                          page={page}
                          pageNumber={pageNumber}
                          isLoading={isLoading}
                          setPage={setPage}
                          totalRecords={totalTeams}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InviteUserModal;
