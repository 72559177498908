import React from "react";
import DepositTypesTable from "../../components/depositTypesTable/DepositTypesTable";
import ClientMenu from "../../components/clientMenu/ClientMenu";

const DepositTypesPage = () => {
  return (
    <>
      <ClientMenu>
        <DepositTypesTable />
      </ClientMenu>
    </>
  );
};

export default DepositTypesPage;
