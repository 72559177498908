import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserById, newUser, updateUser } from "../../services/user/services";
import SubmitButton from "../submitButton/SubmitButton";
import { useNotifications } from "../../context/Notifications";
import { havePermission } from "../../roles/Roles";

const EmployeeEdit = ({ employeeId, teamId }) => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [employee, setEmployee] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    permissions: "employee",
    ext: "",
    status: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    return navigate(-1);
  };

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setEmployee((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmitEmployee = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    if (employeeId !== "new") {
      const response = await updateUser(user, employeeId, employee);

      if (response?.user?.acknowledged) {
        addNotification({
          id: Date.now(),
          message: "Employee updated!",
          kind: "success",
        });
        return navigate(-1);
      } else {
        addNotification({
          id: Date.now(),
          message: "Unexpected error to update the user",
          kind: "danger",
        });
      }
    } else {
      const data = {
        teams: teamId,
        companyId: company.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        permissions: employee.permissions,
        ext: employee.ext,
        email: employee.email,
      };
      const response = await newUser(user, data);

      if (response?.success) {
        addNotification({
          id: Date.now(),
          message: "Employee created!",
          kind: "success",
        });
        return navigate(-1);
      } else {
        addNotification({
          id: Date.now(),
          message: "Unexpected error to create the user",
          kind: "danger",
        });
      }
    }

    setIsLoading(false);
  };

  const getEmployee = React.useCallback(async () => {
    const res = await getUserById(user, employeeId);
    const json = await res;
    setEmployee(json.user);
  }, [employeeId, user]);

  useEffect(() => {
    if (employeeId !== "new") {
      getEmployee();
    }
  }, [employeeId, getEmployee]);

  return (
    <form method="POST" onSubmit={handleSubmitEmployee}>
      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="firstName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Name"
            value={employee?.firstName}
            required
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Last name"
            value={employee?.lastName}
            required
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="permissions"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Select a Rol
          </label>
          <select
            id="permissions"
            name="permissions"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={employee?.permissions}
            onChange={handleOnChange}
            disabled={!havePermission(user, ["admin"])}
          >
            <option value="admin">Admin</option>
            <option value="employee">Employee</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="status"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Select a Status
          </label>
          <select
            id="status"
            name="status"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            defaultValue={employee?.status ? "active" : "deactivate"}
            onChange={handleOnChange}
            disabled={!havePermission(user, ["admin"]) || employeeId === "new"}
          >
            <option value="active">Active</option>
            <option value="deactivate">Deactivate</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="ext"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Extension
          </label>
          <input
            type="text"
            id="ext"
            name="ext"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Extension"
            value={employee?.ext}
            required
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Email"
            value={employee?.email}
            required
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <SubmitButton
          isLoading={isLoading}
          text={"Save"}
          textLoading={"Saving..."}
          key={"saveEmployee"}
        />
      </div>
    </form>
  );
};

export default EmployeeEdit;
