import { setCompany, startLoading, stoptLoading } from "../slices/companySlice";

const baseApi = process.env.REACT_APP_API_BASE;

export const getCompanyById = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    const user = getState().user.user;

    const options = {
      method: "GET", // specifying the HTTP method
      headers: {
        "Content-Type": "application/json", // specifying the Content-Type of the request body
        Authorization: `Bearer ${user?.token}`,
      },
    };

    const response = await fetch(
      `${baseApi}company/byId?companyId=${companyId}`,
      options
    ).then((response) => response.json());

    if (response["_id"]) {
      const company = {
        id: response["_id"],
        name: response.name,
      };
      dispatch(setCompany(company));
      localStorage.setItem("company", JSON.stringify(company));
    }
    dispatch(stoptLoading());
  };
};
