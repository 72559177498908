export const parseEmailHtml = (customer, user, company, html) => {
  const info = {
    "{customerName}": customer?.firstName,
    "{customerLastName}": customer?.lastName,
    "{customerFullName}": customer?.fullName,
    "{customerAccountNumber}": customer.accountNumber,
    "{userName}": user?.firstName,
    "{userLastName}": user?.lastName,
    "{companyName}": company?.name,
  };

  for (const key of Object.keys(info)) {
    if (html.includes(key)) {
      html = html.replace(new RegExp(key, "g"), info[key] || "");
    }
  }

  return html;
};

export function convertSeconds(totalSeconds) {
  // Calculate the number of hours
  const hours = Math.floor(totalSeconds / 3600);
  // Calculate the remaining seconds after extracting hours
  const remainingSecondsAfterHours = totalSeconds % 3600;
  // Calculate the number of minutes from the remaining seconds
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  // Calculate the remaining seconds after extracting minutes
  const seconds = remainingSecondsAfterHours % 60;

  return `${hours > 1 ? hours + " hours - " : ""} ${
    minutes > 1 ? minutes + " minutes - " : ""
  } ${seconds} seconds`;
}
