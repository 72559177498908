import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";

export const getDepositTypes = async (user, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}deposit/getDepositsByCompany?companyId=${companyId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const getAllDepositsByCompany = async (user, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}deposit/getAllDepositsByCompany?companyId=${companyId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const getDepositsByPagination = async (
  user,
  companyId,
  search,
  page,
  pageSize
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}deposit/getDepositsByPagination?companyId=${companyId}&search=${search}&page=${page}&pageSize=${pageSize}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const newDepositsType = async (user, data) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({ data }),
  };

  const response = await fetch(
    `${baseApi}deposit/newDepositType`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const updateDepositType = async (user, depositTypeId, updateFields) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      depositTypeId,
      updateFields,
    }),
  };

  const response = await fetch(
    `${baseApi}deposit/updateDepositType`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};
