import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/userSlice";
import { companySlice } from "./slices/companySlice";
import { customerSlice } from "./slices/customerSlice";
import { templateSlice } from "./slices/templatesSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    company: companySlice.reducer,
    customer: customerSlice.reducer,
    template: templateSlice.reducer,
  },
});
