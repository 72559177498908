import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer: [],
  isLoading: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stoptLoading: (state) => {
      state.isLoading = false;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
      state.isLogged = true;
    },
    singOutCustomer: (state) => {
      state.customer = [];
      state.isLogged = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCustomer, startLoading, stoptLoading, singOutCustomer } =
  customerSlice.actions;
