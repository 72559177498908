import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";

export const getCustomerLogsById = async (user, customerId, type, dateTime) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}logs/getCustomerLogsById?customerId=${customerId}&category=${type}&dateTime=${encodeURIComponent(
      dateTime
    )}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    logs: json ?? [],
  };
};

export const getUserLogsByCompany = async (
  user,
  companyId,
  category,
  agent,
  page,
  pageSize
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}logs/getUserLogsByCompany?companyId=${companyId}&category=${category}&agent=${agent}&page=${page}&pageSize=${pageSize}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const newCustomerLogs = async (user, data) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      ...data,
    }),
  };

  const response = await fetch(`${baseApi}logs/newLog`, options).then(
    (response) => response.json()
  );

  const json = await response;
  if (json?.token === false) store.dispatch(singOut());

  return {
    logs: json ?? [],
  };
};
