import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { newCustomers } from "../../services/customers/services";
import { useNotifications } from "../../context/Notifications";

function ImportCustomer({ modalOpen, toggleModal, loadCustomer }) {
  const { addNotification } = useNotifications();
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [percent, setPercent] = useState(0);
  const [steps, setSteps] = useState(1);
  const [stepsText] = useState([
    "Reading File",
    "Building customers",
    "Saving customers",
    "Complete",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const onChanceFile = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSaveExcelFile = () => {
    readExcelFile(file);
  };

  const readExcelFile = (file) => {
    setIsLoading(true);
    setSteps(1);
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array(data.length);
      for (let i = 0; i < data.length; i++) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const binaryStr = arr.join("");
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      processCustomerData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const processCustomerData = (data) => {
    setSteps(2);
    const customerGroups = [];
    for (let i = 0; i < data.length; i += 100) {
      customerGroups.push(data.slice(i, i + 100));
    }
    saveCustomerGroups(customerGroups);
  };

  const saveCustomerGroups = async (groups) => {
    setSteps(3);
    const responses = [];
    for (let i = 0; i < groups.length; i++) {
      responses.push(await saveCustomers(groups[i]));
      setPercent(((i + 1) / groups.length) * 100);
    }
    setSteps(4);
    if (responses.includes(false)) {
      addNotification({
        id: Date.now(),
        message: "Error to save customer",
        kind: "danger",
      });
    } else {
      addNotification({
        id: Date.now(),
        message: "Customers created!",
        kind: "success",
      });
    }
  };

  const saveCustomers = async (customers) => {
    const response = await newCustomers(user, customers, company.id);
    if (response) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseModal = () => {
    setIsLoading(false);
    setFile(null);
    setPercent(0);
    setSteps(1);
    loadCustomer();
    toggleModal();
  };

  return (
    <>
      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Import Customers
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={handleCloseModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                {isLoading && (
                  <>
                    <div className="flex justify-between mb-1">
                      <span className="text-base font-medium text-blue-700 dark:text-white">
                        {stepsText[steps - 1]}
                      </span>
                      <span className="text-sm font-medium text-blue-700 dark:text-white">
                        {percent}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${percent}%` }}
                      ></div>
                    </div>
                    {steps === 4 && (
                      <>
                        <div className="flex items-center gap-3 mt-3 justify-end">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            onClick={handleCloseModal}
                          >
                            Finish
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!isLoading && !file?.name && (
                  <>
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            .xlsx
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          accept=".xlsx"
                          className="hidden"
                          onChange={onChanceFile}
                        />
                      </label>
                    </div>
                  </>
                )}
                {file?.name && !isLoading && (
                  <div className="flex items-center gap-3">
                    <button
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      onClick={handleSaveExcelFile}
                    >
                      Save Customers
                    </button>
                    <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      {file?.name}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImportCustomer;
