import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNotifications } from "../../context/Notifications";
import SubmitButton from "../submitButton/SubmitButton";
import {
  newDepositsType,
  updateDepositType,
} from "../../services/deposit/services";

function DepositTypeModal({ deposit, text, getDepositTypes, cssButton }) {
  const { user } = useSelector((state) => state.user);
  const { addNotification } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [depositType, setDepositType] = useState(deposit);

  const toggleModal = () => {
    setIsLoading(false);
    setModalOpen(!modalOpen);
  };

  const onChangeDepositType = (event) => {
    const { name, value } = event.target;
    setDepositType((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);

    if (deposit._id === "new") {
      const data = {
        name: depositType.name,
        companyId: user.companyId,
      };
      const response = await newDepositsType(user, data);
      if (response?.success) {
        addNotification({
          id: Date.now(),
          message: "Deposit type created!",
          kind: "success",
        });
        await getDepositTypes();
        toggleModal();
      } else {
        addNotification({
          id: Date.now(),
          message: "Error to create the deposit type!",
          kind: "danger",
        });
      }
    } else {
      const response = await updateDepositType(
        user,
        depositType._id,
        depositType
      );

      if (response?.success) {
        addNotification({
          id: Date.now(),
          message: "Deposit type updated!",
          kind: "success",
        });
        await getDepositTypes();
        toggleModal();
      } else {
        addNotification({
          id: Date.now(),
          message: "Error to update the deposit type!",
          kind: "danger",
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <button className={cssButton} onClick={toggleModal} type="button">
        {text}
      </button>

      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {deposit?._id === "new"
                    ? "Create deposit type"
                    : "Update deposit type"}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={toggleModal}
                  disabled={isLoading}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form className="p-4 md:p-5" onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Name"
                    required
                    defaultValue={depositType.name}
                    onChange={onChangeDepositType}
                  />
                </div>
                {deposit._id !== "new" && (
                  <>
                    <div className="mb-6">
                      <label
                        htmlFor="typeDeposit"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                      >
                        Status
                      </label>
                      <select
                        id="typeDeposit"
                        name="status"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={deposit.status}
                        required
                        onChange={onChangeDepositType}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>Deactivate</option>
                      </select>
                    </div>
                  </>
                )}

                <div className="flex justify-between items-center">
                  <button
                    data-modal-hide="modalID"
                    type="button"
                    className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={toggleModal}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    text={
                      deposit._id === "new"
                        ? "Create Deposit type"
                        : "Update deposit type"
                    }
                    textLoading={
                      deposit._id === "new" ? "Creating" : "Updating"
                    }
                    isLoading={isLoading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DepositTypeModal;
