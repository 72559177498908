import {
  setEmailTemplates,
  setSMSTemplates,
  startLoading,
  stoptLoading,
  setResetEmailTemplates,
} from "../slices/templatesSlice";

const baseApi = process.env.REACT_APP_API_BASE;

export const getTemplates = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    const user = getState().user.user;

    const options = {
      method: "GET", // specifying the HTTP method
      headers: {
        "Content-Type": "application/json", // specifying the Content-Type of the request body
        Authorization: `Bearer ${user?.token}`,
      },
    };

    const response = await fetch(
      `${baseApi}template/getTemplatesByCompany?companyId=${companyId}`,
      options
    ).then((response) => response.json());

    if (response) {
      const templates = response?.templates;

      const emailTemplates = templates?.filter(
        (template) => template.type === "email" && !template.resetPassword
      );

      const smsTemplates = templates?.filter(
        (template) => template.type === "sms" && !template.resetPassword
      );

      const resetEmailTemplates = templates?.filter(
        (template) => template.type === "email" && template.resetPassword
      )[0];

      dispatch(setEmailTemplates(emailTemplates));
      dispatch(setSMSTemplates(smsTemplates));
      dispatch(setResetEmailTemplates(resetEmailTemplates));
      localStorage.setItem("emailTemplates", JSON.stringify(emailTemplates));
      localStorage.setItem("smsTemplates", JSON.stringify(smsTemplates));
      localStorage.setItem(
        "resetEmailTemplates",
        JSON.stringify(resetEmailTemplates)
      );
    }
    dispatch(stoptLoading());
  };
};
