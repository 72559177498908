import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ClientEdit from "../../components/clientEdit/ClientEdit";
import ClientMenu from "../../components/clientMenu/ClientMenu";
import ClientTap from "../../components/clientTap/ClientTap";
import ClientHistory from "../../components/clientHistory/ClientHistory";

const ClientEditPage = () => {
  let { clientId } = useParams();
  const [tapSelected, setTapSelected] = useState(1);

  const handleChangeTap = (tapNumber) => {
    setTapSelected(tapNumber);
  };

  return (
    <>
      <ClientMenu>
        <ClientTap
          tapSelected={tapSelected}
          handleChangeTap={handleChangeTap}
          clientId={clientId}
        />
        {tapSelected === 1 ? (
          <ClientEdit clientId={clientId} />
        ) : (
          <ClientHistory clientId={clientId} />
        )}
      </ClientMenu>
    </>
  );
};

export default ClientEditPage;
