import React, { useState } from "react";
import { useSelector } from "react-redux";
import SubmitButton from "../submitButton/SubmitButton";
import { useNotifications } from "../../context/Notifications";
import { parseEmailHtml } from "../../utils/parse/parse";
import { sendSMS } from "../../services/sms/services";
import BubblePhoneButton from "../bubblePhoneButton/BubblePhoneButton";

function PhoneNumberCommunicationModal({ customer, disabled = false }) {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const { smsTemplates } = useSelector((state) => state.template);
  const { addNotification } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [template, setTemplate] = useState(smsTemplates[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [cssDisabled] = useState({
    true: "text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500",
    false: "text-blue-600 dark:text-blue-500 hover:underline",
  });

  const toggleModal = () => {
    setStep(1);
    setTemplate(smsTemplates[0]);
    setModalOpen(!modalOpen);
  };

  const handleSendMsgStep = () => {
    setStep(2);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const response = await sendSMS(user, customer?._id, template?._id);

    if (response?.success) {
      addNotification({
        id: Date.now(),
        message: "Message sent!",
        kind: "success",
      });

      setIsLoading(false);
      toggleModal();
    } else {
      addNotification({
        id: Date.now(),
        message: "Error to send the message!",
        kind: "danger",
      });
    }
    setIsLoading(false);
  };

  const onChangeTemplate = (event) => {
    const value = event.target.value;
    const temp = smsTemplates.find((temp) => temp._id === value);
    setTemplate(temp);
  };

  return (
    <>
      <button
        onClick={toggleModal}
        disabled={disabled}
        className={`flex items-center text-sm font-medium me-3 ${cssDisabled[disabled]}`}
      >
        <span
          className={`flex w-2.5 h-2.5 bg-${
            disabled ? "slate-300" : "blue-600"
          } dark:bg-${
            disabled ? "gray" : "blue"
          }-600 rounded-full me-1.5 flex-shrink-0`}
        ></span>
        {customer?.phoneNumber}
      </button>

      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Phone Number of {customer?.fullName}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                {step === 1 && (
                  <div className="flex justify-around gap-2">
                    <BubblePhoneButton customer={customer} />

                    <button
                      onClick={handleSendMsgStep}
                      className="max-w-sm p-6 "
                    >
                      <p>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                          <svg
                            width="90px"
                            height="90px"
                            viewBox="-7.92 -7.92 39.84 39.84"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#ffffff"
                            strokeWidth="0.00024000000000000003"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0">
                              <rect
                                x="-7.92"
                                y="-7.92"
                                width="39.84"
                                height="39.84"
                                rx="19.92"
                                fill="#0080ff"
                                strokeWidth="0"
                              ></rect>
                            </g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M7 7.5C6.44772 7.5 6 7.94772 6 8.5C6 9.05228 6.44772 9.5 7 9.5H15C15.5523 9.5 16 9.05228 16 8.5C16 7.94772 15.5523 7.5 15 7.5H7Z"
                                fill="#ffffff"
                              ></path>{" "}
                              <path
                                d="M6 12.5C6 11.9477 6.44772 11.5 7 11.5H17C17.5523 11.5 18 11.9477 18 12.5C18 13.0523 17.5523 13.5 17 13.5H7C6.44772 13.5 6 13.0523 6 12.5Z"
                                fill="#ffffff"
                              ></path>{" "}
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7 2.5C4.23858 2.5 2 4.73858 2 7.5V13.5C2 16.2614 4.23858 18.5 7 18.5H8.58579L11.2929 21.2071C11.6834 21.5976 12.3166 21.5976 12.7071 21.2071L15.4142 18.5H17C19.7614 18.5 22 16.2614 22 13.5V7.5C22 4.73858 19.7614 2.5 17 2.5H7ZM4 7.5C4 5.84315 5.34315 4.5 7 4.5H17C18.6569 4.5 20 5.84315 20 7.5V13.5C20 15.1569 18.6569 16.5 17 16.5H7C5.34315 16.5 4 15.1569 4 13.5V7.5Z"
                                fill="#ffffff"
                              ></path>{" "}
                            </g>
                          </svg>
                        </h5>
                      </p>
                    </button>
                  </div>
                )}

                {step === 2 && (
                  <form method="POST" onSubmit={handleSendMessage}>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                      <div>
                        <label
                          htmlFor="template"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select a template
                        </label>
                        <select
                          id="template"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          defaultValue={smsTemplates[0]?._id}
                          onChange={onChangeTemplate}
                        >
                          {smsTemplates?.map((option) => (
                            <option key={option._id} value={option._id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <br />
                        <SubmitButton
                          isLoading={isLoading}
                          textLoading="Sending..."
                          text={"Send"}
                        />
                      </div>
                    </div>
                    <div className="mb-6">
                      <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Preview
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: parseEmailHtml(
                            customer,
                            user,
                            company,
                            template.text
                          ),
                        }}
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PhoneNumberCommunicationModal;
