import React, { useState } from "react";
import { useSelector } from "react-redux";
import SubmitButton from "../submitButton/SubmitButton";
import { sendEmail } from "../../services/email/services";
import { useNotifications } from "../../context/Notifications";
import { parseEmailHtml } from "../../utils/parse/parse";

function ColdAccountEmailCommunicationModal({ customer, text }) {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const { emailTemplates } = useSelector((state) => state.template);
  const { addNotification } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [template, setTemplate] = useState(emailTemplates[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [classCommunications] = useState({
    true: "flex w-2.5 h-2.5 bg-teal-500 rounded-full me-1.5 flex-shrink-0",
    false: "flex w-2.5 h-2.5 bg-red-500 rounded-full me-1.5 flex-shrink-0",
  });

  const toggleModal = () => {
    setTemplate(emailTemplates[0]);
    setModalOpen(!modalOpen);
  };

  const handleSendEmail = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const response = await sendEmail(user, template?._id, customer?._id);

    if (response?.sentEmail) {
      addNotification({
        id: Date.now(),
        message: "Email sent!",
        kind: "success",
      });

      setIsLoading(false);
      toggleModal();
    } else {
      addNotification({
        id: Date.now(),
        message: "Error to send the email!",
        kind: "danger",
      });
    }
    setIsLoading(false);
  };

  const onChangeTemplate = (event) => {
    const value = event.target.value;
    const temp = emailTemplates.find((temp) => temp._id === value);
    setTemplate(temp);
  };

  return (
    <>
      <span
        onClick={toggleModal}
        className="flex items-center text-sm font-medium me-3 text-blue-600 dark:text-blue-500 hover:underline"
      >
        <span
          className={classCommunications[customer.communications.email]}
        ></span>
        {text}: {customer.communications.email ? "Yes" : "No"}
      </span>

      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Email of {customer.fullName}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <form method="POST" onSubmit={handleSendEmail}>
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        htmlFor="template"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select a template
                      </label>
                      <select
                        id="template"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={emailTemplates[0]?._id}
                        onChange={onChangeTemplate}
                      >
                        {emailTemplates?.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <br />
                      <SubmitButton
                        isLoading={isLoading}
                        textLoading="Sending..."
                        text={"Send"}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Preview
                    </span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseEmailHtml(
                          customer,
                          user,
                          company,
                          template.html
                        ),
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ColdAccountEmailCommunicationModal;
