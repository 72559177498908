import { createSlice } from "@reduxjs/toolkit";

const emailTemplatesStage = localStorage.getItem("emailTemplates");
const smsTemplatesStage = localStorage.getItem("smsTemplates");
const resetEmailTemplatesStage = localStorage.getItem("resetEmailTemplates");

const initialState = {
  emailTemplates:
    JSON.parse(emailTemplatesStage)?.length > 0
      ? JSON.parse(emailTemplatesStage)
      : [],
  smsTemplates:
    JSON.parse(smsTemplatesStage)?.length > 0
      ? JSON.parse(smsTemplatesStage)
      : [],
  resetEmailTemplates:
    JSON.parse(resetEmailTemplatesStage)?.length > 0
      ? JSON.parse(resetEmailTemplatesStage)
      : {},
  isLoading: false,
};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stoptLoading: (state) => {
      state.isLoading = false;
    },
    setEmailTemplates: (state, action) => {
      state.emailTemplates = action.payload;
    },
    setResetEmailTemplates: (state, action) => {
      state.resetEmailTemplates = action.payload;
    },
    setSMSTemplates: (state, action) => {
      state.smsTemplates = action.payload;
    },
    singOutTemplates: (state) => {
      state.emailTemplates = [];
      state.smsTemplates = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSMSTemplates,
  startLoading,
  stoptLoading,
  setEmailTemplates,
  singOutTemplates,
  setResetEmailTemplates,
} = templateSlice.actions;
