import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import SubmitButton from "../../components/submitButton/SubmitButton";
import { useNavigate } from "react-router-dom";
import { newPassword as newPasswordF } from "../../services/user/services";
import { useNotifications } from "../../context/Notifications";
import Alerts from "../../components/alerts/Alerts";
import { lockIcon } from "../../utils/historyIcons/historyIcons";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { notifications, removeNotification, addNotification } =
    useNotifications();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [errorOnConfirm, setErrorOnConfirm] = useState("");

  const [currentPasswordisVisible, setCurrentVisible] = useState(false);
  const [passwordisVisible, setNewVisible] = useState(false);
  const [confirmPasswordisVisible, setConfirmVisible] = useState(false);

  const currentPasswordVisibility = currentPasswordisVisible
    ? "text"
    : "password";
  const passwordVisibility = passwordisVisible ? "text" : "password";
  const confirmPasswordVisibility = confirmPasswordisVisible
    ? "text"
    : "password";

  const validatePassword = (password) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const onChangePassword = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    if (value.length < 8) {
      setError("New password must be at least 8 characters long.");
      return;
    }
    if (!validatePassword(value)) {
      setError(
        "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    setError(null);
  };

  const onConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    if (newPassword !== value) {
      setErrorOnConfirm("New password and confirm password do not match.");
      return;
    }
    setErrorOnConfirm(null);
  };

  const handleRemoveAlert = (id) => {
    removeNotification(id);
  };

  const handleSubmitPassword = async (event) => {
    event.preventDefault();

    if (error === null && errorOnConfirm === null) {
      setIsLoading(true);

      const response = await newPasswordF(email, currentPassword, newPassword);

      if (response?.success) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setEmail("");
        addNotification({
          id: Date.now(),
          message: "Password updated!",
          kind: "success",
        });
        navigate("/login", { replace: true });
      } else {
        addNotification({
          id: Date.now(),
          message: "Wrong Email!",
          kind: "danger",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <div className=" bg-white dark:bg-gray-800 w-screen h-screen overflow-scroll flex-col items-center content-center">
      {notifications.map((noti) => {
        return (
          <Alerts
            key={noti.id}
            id={noti.id}
            kind={noti.kind}
            text={noti.message}
            handleRemoveAlert={handleRemoveAlert}
          />
        );
      })}
      <div className="flex justify-center">{lockIcon("100px", "100px")}</div>

      <div className=" mb-4 flex lg:py-3 justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-wrap mb-3 text-center font-bold text-2xl lg:text-4xl tracking-tight dark:text-white">
            CHANGE YOUR PASSWORD
          </h1>
          <h1 className="text-wrap w-80 lg:w-96 tracking-tight dark:text-white text-center">
            Your password must contain at least 8 characters, it must also
            include at least one upper case letter, one lower case letter, one
            number and one special character.
          </h1>
        </div>
      </div>
      <form
        onSubmit={handleSubmitPassword}
        className="flex justify-center items-center "
      >
        <div className="max-w-md">
          <div className="flex justify-center mb-6">
            <div className="grid gap-6 mb-6 md:grid-cols-1 justify-items-center md:justify-items-stretch lg:justify-items-stretch md:min-w-96 lg:min-w-96">
              <div className="w-56 lg:w-full">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input
                    type={"email"}
                    id="email"
                    className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="current_password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Temporal Password
                </label>
                <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input
                    type={currentPasswordVisibility}
                    id="current_password"
                    className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="•••••••••"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                  <div
                    className="p-2 cursor-pointer text-black dark:text-white"
                    onClick={() => setCurrentVisible(!currentPasswordisVisible)}
                  >
                    {currentPasswordisVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New Password
                </label>
                <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input
                    type={passwordVisibility}
                    id="password"
                    className="bg-gray-50 border border-r-0 border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="•••••••••"
                    value={newPassword}
                    onChange={onChangePassword}
                    required
                  />
                  <div
                    className="p-2 cursor-pointer text-black dark:text-white"
                    onClick={() => setNewVisible(!passwordisVisible)}
                  >
                    {passwordisVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
                {error && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {error}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm Password
                </label>
                <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input
                    type={confirmPasswordVisibility}
                    id="confirm_password"
                    className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="•••••••••"
                    value={confirmPassword}
                    onChange={onConfirmPassword}
                    required
                  />
                  <div
                    className="p-2 cursor-pointer text-black dark:text-white"
                    onClick={() => setConfirmVisible(!confirmPasswordisVisible)}
                  >
                    {confirmPasswordisVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
                {errorOnConfirm && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {errorOnConfirm}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex justify-center">
              <SubmitButton
                isLoading={isLoading}
                textLoading="Changing Password..."
                text={"Change Password"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
