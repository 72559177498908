//import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "../components/login/Login";
import App from "../App";
import store from "../store/store";
import SignOut from "../components/signOut/SignOut";
import ErrorPage from "../components/errorPage/ErrorPage";
import ClientsPage from "../pages/clients/Clients";
import ColdClientPage from "../pages/coldAccounts/ColdAccounts";
import DepositsPage from "../pages/deposits/Deposits";
import PayoutsPage from "../pages/payouts/Payouts";
import HomePage from "../pages/home/Home";
import SettingsPage from "../pages/settings/Settings";
import TeamsPage from "../pages/teams/Teams";
import TeamPage from "../pages/team/Team";
import EmployeeEditPage from "../pages/employeeEdit/EmployeeEdit";
import ClientEditPage from "../pages/clientEdit/ClientEdit";
import TeamHistory from "../pages/teamHistory/TeamHistory";
import ChangePasswordPage from "../pages/changePassword/changePassword";
import EnterMailPage from "../pages/enterMail/enterMail.jsx";
import DepositTypesPage from "../pages/depositTypes/DepositTypesPage.jsx";

const ProtectedRoute = ({ children }) => {
  const isLogged = store.getState().user.isLogged;

  if (!isLogged) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

/*
const ProtectedRoutePermission = ({
  redirectPath = "/home",
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
*/

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients",
        element: (
          <ProtectedRoute>
            <ClientsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients/:clientId",
        element: (
          <ProtectedRoute>
            <ClientEditPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients/cold",
        element: (
          <ProtectedRoute>
            <ColdClientPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients/payments",
        element: (
          <ProtectedRoute>
            <PayoutsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients/deposits",
        element: (
          <ProtectedRoute>
            <DepositsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/team",
        element: (
          <ProtectedRoute>
            <TeamsPage />
          </ProtectedRoute>
        ),
      },

      {
        path: "/team/history",
        element: (
          <ProtectedRoute>
            <TeamHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: "/team/:teamId/:employeeId",
        element: (
          <ProtectedRoute>
            <EmployeeEditPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/team/:teamId",
        element: (
          <ProtectedRoute>
            <TeamPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/team/new",
        element: (
          <ProtectedRoute>
            <>hola</>
          </ProtectedRoute>
        ),
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/clients/deposit",
        element: (
          <ProtectedRoute>
            <DepositTypesPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "sign-out",
        element: (
          <ProtectedRoute>
            <SignOut />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/changePassword",
    element: <ChangePasswordPage />,
  },
  {
    path: "/enterMail",
    element: <EnterMailPage />,
  },
]);
