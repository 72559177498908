import { createSlice } from "@reduxjs/toolkit";

const storage = localStorage.getItem("user");

const initialState = (storage ? JSON.parse(storage)?.id : undefined)
  ? {
      user: JSON.parse(storage),
      isLogged: true,
      isLoading: false,
      resetPassword: false,
    }
  : {
      user: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        token: "",
        companyId: "",
        permissions: [],
      },
      isLogged: false,
      isLoading: false,
      resetPassword: false,
    };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stoptLoading: (state) => {
      state.isLoading = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLogged = true;
    },
    setResetPassword: (state, action) => {
      state.resetPassword = action.payload;
    },
    singOut: (state) => {
      state.user = {};
      localStorage.setItem("user", JSON.stringify({}));
      state.isLogged = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  startLoading,
  stoptLoading,
  singOut,
  setResetPassword,
} = userSlice.actions;
