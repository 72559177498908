import React, { useEffect, useState } from "react";
import ClientSearch from "../clientSearch/ClientSearch";
import HistoryCard from "../historyCard/HistoryCard";
import { getCustomerLogsById } from "../../services/logs/services";
import { useSelector } from "react-redux";
import Loading from "../loading/Loading";
import NoResults from "../noResults/noResults";

const ClientHistory = ({ clientId }) => {
  const { user } = useSelector((state) => state.user);
  const [type, setType] = useState("all");
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date()?.toISOString());

  const getHistories = React.useCallback(async () => {
    setIsLoading(true);
    const res = await getCustomerLogsById(user, clientId, type, date);
    const json = await res;
    setHistories(json?.logs?.length ? json?.logs : []);
    setIsLoading(false);
  }, [user, clientId, type, date]);

  useEffect(() => {
    getHistories();
  }, [getHistories]);

  return (
    <>
      <ClientSearch type={type} setType={setType} setDate={setDate} />

      <div className="grid gap-6 mb-6 md:grid-cols-1">
        {isLoading && <Loading width="10" height="10" />}
        {!isLoading && histories.length === 0 && (
          <NoResults message="No historial found" />
        )}

        {histories.length > 0 && (
          <>{!isLoading && <HistoryCard histories={histories} />}</>
        )}
      </div>
    </>
  );
};

export default ClientHistory;
