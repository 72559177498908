import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientHistory } from "../../utils/enum/enums";
import { sortByText } from "../../utils/sort/sort";
import { newCustomerLogs } from "../../services/logs/services";
import { useNotifications } from "../../context/Notifications";
import { getDepositTypes } from "../../services/deposit/services";

function HistoryModal({ customer }) {
  const { user } = useSelector((state) => state.user);
  const { addNotification } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [depositTypes, setDepositTypes] = useState([]);
  const [depositType, setDepositType] = useState("");
  const [type, setType] = useState(clientHistory.COMMENT);
  const [options, setOptions] = useState([
    {
      id: clientHistory.DEPOSIT,
      text: "Deposit",
    },
    {
      id: clientHistory.COMMENT,
      text: "Comment",
    },
  ]);

  const toggleModal = () => {
    setIsLoading(false);
    setType(clientHistory.COMMENT);
    setDepositType(depositTypes[0]?.code);
    setAmount("");
    setComment("");
    setModalOpen(!modalOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    const data = {
      accountNumber: customer?.accountNumber,
      type,
      agent: user.ext,
      comment,
      ...(type === clientHistory.DEPOSIT && {
        depositType,
        amount,
      }),
    };

    const response = await newCustomerLogs(user, data);
    if (response?.logs?.acknowledged) {
      addNotification({
        id: Date.now(),
        message: "Log created!",
        kind: "success",
      });

      toggleModal();
    } else {
      addNotification({
        id: Date.now(),
        message: "Error to create the log!",
        kind: "danger",
      });
    }
    setIsLoading(false);
  };

  const onChangeMessage = (event) => {
    const value = event.target.value;
    setComment(value);
  };

  const onChangeType = (event) => {
    const value = event.target.value;
    setType(value);
  };

  const onChangeDepositType = (event) => {
    const value = event.target.value;
    setDepositType(value);
  };

  const onChangeAmount = (event) => {
    const value = event.target.value;
    setAmount(value);
  };

  const getDepositTypesCall = React.useCallback(async () => {
    const data = await getDepositTypes(user, user.companyId);
    setDepositTypes(data.deposits);
    setDepositType(depositTypes[0]?.code);
  }, [depositTypes, user]);

  useEffect(() => {
    setOptions((options) => {
      return sortByText(options);
    });

    getDepositTypesCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        onClick={toggleModal}
        type="button"
      >
        Create Communication Log
      </button>

      {modalOpen && (
        <div
          id="modalID"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  New Communication Log
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="modalID"
                  onClick={toggleModal}
                  disabled={isLoading}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <div className="mb-6">
                  <label
                    htmlFor="type"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select a type
                  </label>
                  <select
                    id="type"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={type}
                    required
                    onChange={onChangeType}
                  >
                    {options.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
                {type === clientHistory.DEPOSIT && (
                  <>
                    <div className="mb-6">
                      <label
                        htmlFor="typeDeposit"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Deposit type
                      </label>
                      <select
                        id="typeDeposit"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={depositType}
                        required
                        onChange={onChangeDepositType}
                      >
                        {depositTypes.map((deposit) => (
                          <option key={deposit._id} value={deposit.code}>
                            {deposit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="amount"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Amount
                      </label>
                      <input
                        type="number"
                        id="amount"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Amount"
                        defaultValue={amount}
                        required
                        onChange={onChangeAmount}
                      />
                    </div>
                  </>
                )}
                <div className="mb-6">
                  <label
                    htmlFor="comment"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    required
                  >
                    Your comment
                  </label>
                  <textarea
                    id="comment"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                    defaultValue={comment}
                    required
                    onChange={onChangeMessage}
                  ></textarea>
                </div>

                <div className="flex justify-between items-center">
                  <button
                    data-modal-hide="modalID"
                    type="button"
                    className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={toggleModal}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    Create Log
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HistoryModal;
