import React from "react";
import TeamMenu from "../../components/teamMenu/TeamMenu";
import HistoryTeam from "../../components/historyTeam/HistoryTeam";

const TeamHistory = () => {
  return (
    <>
      <TeamMenu>
        <HistoryTeam />
      </TeamMenu>
    </>
  );
};

export default TeamHistory;
