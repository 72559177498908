import React from "react";
import ColdClientTable from "../../components/coldClientTable/ColdClientTable";
import ClientMenu from "../../components/clientMenu/ClientMenu";

const ColdClientPage = () => {
  return (
    <>
      <ClientMenu>
        <ColdClientTable />
      </ClientMenu>
    </>
  );
};

export default ColdClientPage;
