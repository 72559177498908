import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTeamsByCompany } from "../../services/teams/services";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import NoResults from "../noResults/noResults";
import CreateTeamModal from "../createTeamModal/CreateTeamModal";

const TableTeams = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const navigate = useNavigate();
  const [teamsData, setTeams] = useState([]);
  // track page number
  const [page, setPage] = useState(1);
  // total number of records
  const [pageNumber, setPageNumber] = useState(0);
  // track API response time
  const [isLoading, setIsLoading] = useState(false);
  const [totalTeams, setTotalTeams] = useState(0);
  const [search, setSearch] = useState("");

  const viewEmployee = (employeeId) => {
    return navigate(`/team/${employeeId}`);
  };

  const handleOnChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);

    setPage(1);
  };

  const getTeams = React.useCallback(async () => {
    setIsLoading(true);

    const data = await getTeamsByCompany(user, company.id, page, search, 5);
    setIsLoading(false);
    setTeams(data?.teams ?? []);
    const count = data?.totalTeams ?? 0;
    setTotalTeams(count);
    setPageNumber(Math.ceil(count / 5));
  }, [company.id, page, search, user]);

  useEffect(() => {
    getTeams();
  }, [getTeams]);
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="grid gap-6 mb-6 md:grid-cols-2  mt-2 mr-2 ml-2">
        <div>
          <label
            htmlFor="search"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Search
          </label>
          <input
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..."
            onChange={handleOnChangeSearch}
          />
        </div>

        <div className="flex justify-end items-end">
          <CreateTeamModal key={"createTeamModal"} getTeams={getTeams} />
        </div>
      </div>

      {!isLoading && teamsData.length === 0 && (
        <NoResults message="No teams found" />
      )}
      {teamsData.length === 0 && isLoading && <Loading />}
      {teamsData.length > 0 && (
        <>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Members
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {teamsData?.map((team) => {
                return (
                  <tr
                    key={team._id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {team?.name}
                    </th>
                    <td className="px-6 py-4">{team.userCount}</td>
                    <td className="px-6 py-4 text-right">
                      <button
                        type="button"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => {
                          viewEmployee(team._id);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading && <Loading />}
          {!isLoading && (
            <Pagination
              key={"paginationTeams"}
              data={teamsData}
              page={page}
              pageNumber={pageNumber}
              isLoading={isLoading}
              setPage={setPage}
              totalRecords={totalTeams}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TableTeams;
