import React from "react";
import { useParams } from "react-router-dom";
import TeamMenu from "../../components/teamMenu/TeamMenu";
import TableTeam from "../../components/tableTeam/TableTeam";

const TeamPage = () => {
  let { teamId } = useParams();

  return (
    <>
      <TeamMenu>
        <TableTeam teamId={teamId} />
      </TeamMenu>
    </>
  );
};

export default TeamPage;
