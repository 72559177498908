import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";

export const sendEmail = async (user, templateId, customerId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      templateId,
      customerId,
      userId: user.id,
    }),
  };

  const response = await fetch(`${baseApi}mail/sendEmail`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const forgetPassword = async (email) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
    },
    body: JSON.stringify({
      email,
    }),
  };

  const response = await fetch(
    `${baseApi}forgetPassword/resetPassword`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};
