import React from "react";
import LoadingButton from "../loadingButton/LoadingButton";

const SubmitButton = ({ isLoading, text, textLoading = "Loading...." }) => {
  return (
    <div className="flex justify-end">
      {isLoading ? (
        <LoadingButton text={textLoading} />
      ) : (
        <button
          type="submit"
          disabled={isLoading}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {text}
        </button>
      )}
    </div>
  );
};

export default SubmitButton;
