import { singOut } from "../../store/slices/userSlice";
import store from "../../store/store";

export const getTeamsByCompany = async (
  user,
  companyId,
  page,
  search,
  pageSize
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}team/getTeamsByCompany?companyId=${companyId}&page=${page}&pageSize=${pageSize}&search=${search}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    teams: json?.teams ?? [],
    totalTeams: json?.totalRecords ?? 0,
  };
};

export const getTeamMembers = async (
  user,
  teamId,
  companyId,
  page,
  pageSize,
  search
) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}team/getTeamsMembers?companyId=${companyId}&teamId=${teamId}&page=${page}&pageSize=${pageSize}&search=${search}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return {
    teams: json?.users ?? [],
    totalTeams: json?.totalRecords ?? 0,
  };
};

export const getTeamById = async (user, teamId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await fetch(
    `${baseApi}team/getTeamById?&teamId=${teamId}`,
    options
  ).then((response) => response.json());

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};

export const createTeam = async (user, teamName, companyId) => {
  const baseApi = process.env.REACT_APP_API_BASE;

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      teamName,
      companyId,
    }),
  };

  const response = await fetch(`${baseApi}team/createTeam`, options).then(
    (response) => response.json()
  );

  const json = await response;

  if (json?.token === false) store.dispatch(singOut());

  return json;
};
