import {
  setResetPassword,
  setUser,
  startLoading,
  stoptLoading,
} from "../slices/userSlice";
import { getCompanyById } from "./companyThunk";
import { encryptText } from "../../utils/encryptText/encryptText";
import { getTemplates } from "./templateThunk";

const baseApi = process.env.REACT_APP_API_BASE;

export const getUserByUserAndPassword = (
  username,
  password,
  addNotification
) => {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    const hashedPassword = encryptText(password);
    const response = await fetch(
      `${baseApi}user/login?username=${encodeURIComponent(
        username
      )}&password=${encodeURIComponent(hashedPassword)}`
    ).then((response) => response.json());

    if (response["_id"]) {
      const user = {
        id: response["_id"],
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        username: response.username,
        token: response.token,
        companyId: response.companyId,
        permissions: response.permissions,
        ext: response.ext,
      };
      dispatch(setUser(user));
      localStorage.setItem("user", JSON.stringify(user));

      dispatch(getCompanyById(user.companyId));
      dispatch(getTemplates(user.companyId));
      dispatch(stoptLoading());
    } else if (response?.resetPassword) {
      dispatch(setResetPassword(true));
      dispatch(stoptLoading());
    } else {
      addNotification({
        id: Date.now(),
        message: "Wrong credentials!",
        kind: "danger",
      });
      dispatch(stoptLoading());
    }
  };
};

export const registerUser = (email, password, username, name) => {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, name, user: username }),
    };
    const response = await fetch(`${baseApi}user/`, requestOptions).then(
      (response) => response.json()
    );
    if (response.code !== 1) {
      const responseLog = await fetch(
        `${baseApi}user/${username}/${password}`
      ).then((res) => res.json());

      if (responseLog?.response["_id"]) {
        dispatch(
          setUser({
            id: responseLog.response.id,
            email: responseLog.response.email,
            name: responseLog.response.name,
            user: responseLog.response.user,
          })
        );
        dispatch(stoptLoading());
      }
    }
  };
};
