import React from "react";

const Pagination = ({
  pageNumber,
  totalRecords,
  page,
  setPage,
  isLoading,
  data,
  pageSize = 5,
}) => {
  const handleClick = (page) => {
    if (page >= 1 && page <= pageNumber) {
      setPage(page);
    }
  };

  const handleNextPage = () => {
    setPage((prevValue) => prevValue + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevValue) => prevValue - 1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10;

    if (pageNumber <= maxPagesToShow) {
      for (let i = 1; i <= pageNumber; i++) {
        pageNumbers.push(
          <li key={i}>
            <button
              onClick={() => handleClick(i)}
              className={`flex items-center justify-center px-4 h-8 leading-tight ${
                page === i
                  ? "text-blue-600 border border-gray-300 bg-blue-50"
                  : "text-gray-500 bg-white border border-gray-300"
              } hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            >
              {i}
            </button>
          </li>
        );
      }
    } else {
      const createButton = (pageB) => (
        <li key={page}>
          <button
            onClick={() => handleClick(page)}
            className={`flex items-center justify-center px-4 h-8 leading-tight ${
              pageB === page
                ? "text-blue-600 border border-gray-300 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300"
            } hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
          >
            {pageB}
          </button>
        </li>
      );

      pageNumbers.push(createButton(1));
      if (page > 4) {
        pageNumbers.push(
          <li key="start-ellipsis">
            <div className="mr-2 ml-2">...</div>
          </li>
        );
      }

      const startPage = Math.max(2, page - 2);
      const endPage = Math.min(pageNumber - 1, page + 2);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(createButton(i));
      }

      if (page < pageNumber - 3) {
        pageNumbers.push(
          <li key="end-ellipsis">
            <div className="mr-2 ml-2">...</div>
          </li>
        );
      }

      pageNumbers.push(createButton(pageNumber));
    }

    return pageNumbers;
  };

  return (
    <>
      {pageNumber > 1 && (
        <nav
          className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4 mb-2 mr-2 ml-2"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
            Showing{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {data.length <= pageSize ? data.length : pageSize}
            </span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {totalRecords}
            </span>
          </span>
          <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li>
              <button
                className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={handlePreviousPage}
                disabled={isLoading || page === 1}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers()}
            <li>
              <button
                type="button"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={handleNextPage}
                disabled={isLoading || pageNumber === page}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;
