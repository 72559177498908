import React, { useEffect, useState } from "react";
import Datepicker from "tailwind-datepicker-react";
import { clientHistory } from "../../utils/enum/enums";
import { sortByText } from "../../utils/sort/sort";

const ClientSearch = ({ setDate, type, setType }) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([
    {
      id: clientHistory.ALL,
      text: "All",
    },
    {
      id: clientHistory.CALL,
      text: "Call",
    },
    {
      id: clientHistory.MESSAGE,
      text: "Message",
    },
    {
      id: clientHistory.EMAIL,
      text: "Email",
    },
    {
      id: clientHistory.DEPOSIT,
      text: "Deposit",
    },
    {
      id: clientHistory.COMMENT,
      text: "Comment",
    },
  ]);
  const [dateOptions] = useState({
    title: "Logs Date",
    autoHide: true,
    todayBtn: true,
    clearBtn: false,
    clearBtnText: "Clear",
    maxDate: new Date(),
    minDate: new Date("2024-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "text-gray-900 dark:text-white",
      disabledText: "text-slate-300",
      input: "h-14",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "absolute",
    defaultDate: new Date(),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  });

  const handleChange = (selectedDate) => {
    setDate(selectedDate.toISOString());
  };

  const handleClose = (state) => {
    setShow(state);
  };

  const handleOnChangeSelect = (event) => {
    const value = event.target.value;
    setType(value);
  };

  useEffect(() => {
    setOptions((options) => {
      return sortByText(options);
    });
  }, []);

  return (
    <>
      <form className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Filter By
          </label>
          <select
            id="type"
            className="p-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            defaultChecked={type}
            onChange={handleOnChangeSelect}
          >
            {options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <label
            htmlFor="type"
            className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
          >
            Date
          </label>
          <Datepicker
            options={dateOptions}
            onChange={handleChange}
            show={show}
            setShow={handleClose}
          />
        </div>
      </form>
    </>
  );
};

export default ClientSearch;
