import React from "react";
import ClientMenu from "../../components/clientMenu/ClientMenu";

const DepositsPage = () => {
  return (
    <>
      <ClientMenu>
        <p>Deposits</p>
      </ClientMenu>
    </>
  );
};

export default DepositsPage;
