import React from "react";
import TeamMenu from "../../components/teamMenu/TeamMenu";
import TableTeams from "../../components/tableTeams/TableTeams";

const TeamsPage = () => {
  return (
    <>
      <TeamMenu>
        <TableTeams />
      </TeamMenu>
    </>
  );
};

export default TeamsPage;
