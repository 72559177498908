import React from "react";
import ClientMenu from "../../components/clientMenu/ClientMenu";
import TableClients from "../../components/tableClients/TableClients";

const ClientsPage = () => {
  return (
    <>
      <ClientMenu>
        <TableClients />
      </ClientMenu>
    </>
  );
};

export default ClientsPage;
