import React from "react";
import { useParams } from "react-router-dom";
import EmployeeEdit from "../../components/employeeEdit/EmployeeEdit";

const EmployeeEditPage = () => {
  let { employeeId, teamId } = useParams();
  return (
    <>
      <EmployeeEdit employeeId={employeeId} teamId={teamId} />
    </>
  );
};

export default EmployeeEditPage;
