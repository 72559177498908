import React, { useEffect, useState } from "react";
import { teamHistory } from "../../utils/enum/enums";
import { sortByText } from "../../utils/sort/sort";

const TeamSearch = ({ agents, setAgent, type, setType }) => {
  const [options, setOptions] = useState([
    {
      id: teamHistory.ALL,
      text: "All",
    },
    {
      id: teamHistory.CALL,
      text: "Call",
    },
    {
      id: teamHistory.MESSAGE,
      text: "Message",
    },
    {
      id: teamHistory.EMAIL,
      text: "Email",
    },
    {
      id: teamHistory.DEPOSIT,
      text: "Deposit",
    },
    {
      id: teamHistory.COMMENT,
      text: "Comment",
    },
    {
      id: teamHistory.LOGIN,
      text: "Login",
    },
    {
      id: teamHistory.NEWUSER,
      text: "New User",
    },
  ]);

  const handleOnChangeAgent = (event) => {
    const value = event.target.value;
    setAgent(value);
  };

  const handleOnChangeSelect = (event) => {
    const value = event.target.value;
    setType(value);
  };

  useEffect(() => {
    setOptions((options) => {
      return sortByText(options);
    });
  }, []);

  return (
    <>
      <form className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="type"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Filter type
          </label>
          <select
            id="type"
            className="p-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            defaultChecked={type}
            onChange={handleOnChangeSelect}
          >
            {options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="users"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Filter Employee
          </label>
          <select
            id="users"
            className="p-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            defaultChecked={"all"}
            onChange={handleOnChangeAgent}
          >
            <option key={"all"} value={"all"}>
              All Employees
            </option>
            {agents?.map((option) => (
              <option key={option._id} value={option.ext}>
                {option.firstName} {option.lastName}
              </option>
            ))}
          </select>
        </div>
      </form>
    </>
  );
};

export default TeamSearch;
