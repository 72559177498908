import React from "react";
import {
  clientIcon,
  coldIcon,
  depositIcon,
  payoutIcon,
  teamIcon,
} from "../../utils/historyIcons/historyIcons";

const Home = () => {
  return (
    <div className="container lg:py-3 max-w-screen-lg ">
      <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  ">
        <a
          href="/clients"
          className="relative block order-last max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <div className="">
            <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
              Customer
            </h5>
            <p className="font-normal dark:text-gray-400 ">
              Administrate your customers
            </p>
            <div className="absolute inset-y-3 right-3">{clientIcon()}</div>
          </div>
        </a>

        <a
          href="/team"
          className="relative block max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 hover:stroke-white "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
            Team
          </h5>
          <p className="font-normal dark:text-gray-400">See and manage teams</p>
          <div className="absolute inset-y-3 right-3 hover:text-white">
            {teamIcon()}
          </div>
        </a>

        <a
          href="/clients/cold"
          className="relative block max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Cold Accounts
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Filter inactive accounts
          </p>
          <div className="absolute inset-y-3 right-3">{coldIcon()}</div>
        </a>
        <a
          href="/clients/deposits"
          className="hidden relative max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Deposit
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            description
          </p>
          <div className="absolute inset-y-3 right-3">{depositIcon}</div>
        </a>
        <a
          href="/clients/payments"
          className="relative hidden max-w-sm p-6 text-black border border-blue-700 hover:bg-gray-200 hover:dark:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:border-blue-500 dark:text-blue-500 "
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Payouts
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            description
          </p>
          <div className="absolute inset-y-3 right-3">{payoutIcon()}</div>
        </a>
      </div>
    </div>
  );
};

export default Home;
