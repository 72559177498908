export const clientHistory = {
  ALL: "all",
  CALL: "CL",
  MESSAGE: "SMS",
  EMAIL: "EMAIL",
  DEPOSIT: "DP",
  COMMENT: "CMM",
};

export const teamHistory = {
  ALL: "all",
  CALL: "CL",
  MESSAGE: "SMS",
  EMAIL: "EMAIL",
  DEPOSIT: "DP",
  COMMENT: "CMM",
  LOGIN: "LG",
  NEWUSER: "NEWUSER",
};
