import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HistoryCard from "../historyCard/HistoryCard";
import TeamSearch from "../teamSearch/TeamSearch";
import { getUserLogsByCompany } from "../../services/logs/services";
import Loading from "../loading/Loading";
import Pagination from "../pagination/Pagination";
import { getUsersByCompany } from "../../services/user/services";
import NoResults from "../noResults/noResults";

const HistoryTeam = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [agent, setAgent] = useState("all");
  const [agents, setAgents] = useState(null);
  const [type, setType] = useState("all");
  const [histories, setHistories] = useState([]);
  const [page, setPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalHistories, setTotalHistories] = useState(0);

  const getHistories = React.useCallback(async () => {
    setIsLoading(true);
    const res = await getUserLogsByCompany(
      user,
      company.id,
      type,
      agent,
      page,
      "10"
    );
    const json = await res;
    setHistories(json?.logs?.length ? json?.logs : []);
    const count = json?.totalRecords ?? 0;
    setTotalHistories(count);
    setPageNumber(Math.ceil(count / 10));
    setIsLoading(false);
  }, [agent, company.id, page, type, user]);

  useEffect(() => {
    getHistories();
  }, [getHistories]);

  const getUsers = React.useCallback(async () => {
    setIsLoading(true);
    const res = await getUsersByCompany(user, company.id);
    const json = await res;
    setAgents(json);
    setIsLoading(false);
  }, [company.id, user]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <TeamSearch
        agents={agents}
        setAgent={setAgent}
        type={type}
        setType={setType}
      />

      {!isLoading && histories.length === 0 && (
        /*<NoResults message={`No ${type} found`} />*/
        <NoResults message="No historial found" />
      )}
      <div className="grid gap-6 mb-6 md:grid-cols-1">
        {!isLoading && (
          <>
            <HistoryCard histories={histories} />{" "}
            <Pagination
              key={"paginationLogs"}
              data={histories}
              page={page}
              pageNumber={pageNumber}
              isLoading={isLoading}
              setPage={setPage}
              totalRecords={totalHistories}
              pageSize={10}
            />
          </>
        )}
        {isLoading && <Loading />}
      </div>
    </>
  );
};

export default HistoryTeam;
