import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HistoryModal from "../historyModal/HistoryModal";
import {
  getCustomerById,
  newCustomer,
  updateCustomerById,
} from "../../services/customers/services";
import { useNotifications } from "../../context/Notifications";
import SubmitButton from "../submitButton/SubmitButton";

const ClientEdit = ({ clientId }) => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [client, setClient] = useState({
    firstName: "",
    lastName: "",
    accountNumber: "",
    email: "",
    phoneNumber: "",
    category: "gd",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    return navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    if (clientId !== "new") {
      const response = await updateCustomerById(user, clientId, client);
      if (response?.customer?.customers?.acknowledged) {
        addNotification({
          id: Date.now(),
          message: "Customer updated!",
          kind: "success",
        });
        return navigate(-1);
      } else {
        addNotification({
          id: Date.now(),
          message: "Error to update the customer!",
          kind: "danger",
        });
      }
    } else {
      const response = await newCustomer(user, {
        ...client,
        companyId: company.id,
      });

      if (response?.customer?.acknowledged) {
        addNotification({
          id: Date.now(),
          message: "New Customer!",
          kind: "success",
        });
        return navigate(-1);
      } else {
        addNotification({
          id: Date.now(),
          message: "Error to create the customer!",
          kind: "danger",
        });
      }
    }
    setIsLoading(false);
  };

  const getClient = React.useCallback(async () => {
    if (clientId && clientId !== "new") {
      const data = await getCustomerById(user, clientId);
      setClient(data.customer);
    }
  }, [clientId, user]);

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setClient((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getClient();
  }, [getClient]);
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label
            htmlFor="firstName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="First Name"
            value={client?.firstName}
            required
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Last Name"
            value={client?.lastName}
            required
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="accountNumber"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Account number
          </label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            disabled={clientId !== "new"}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Account number"
            value={client?.accountNumber}
            onChange={handleOnChange}
            required
          />
        </div>
        <div>
          <label
            htmlFor="category"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Select a Category
          </label>
          <select
            id="category"
            name="category"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={client?.category}
            onChange={handleOnChange}
            defaultValue={"gd"}
          >
            <option value="gd">Good</option>
            <option value="cd">Cold</option>
            <option value="bl">Black list</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Email"
            value={client?.email}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <label
            htmlFor="phoneNumber"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Phone number
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Phone number"
            value={client?.phoneNumber}
            onChange={handleOnChange}
            required
          />
        </div>
      </div>
      <div className={`mb-6 ${clientId === "new" ? "hidden" : ""}`}>
        <label
          htmlFor="communications"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Communications
        </label>
        <HistoryModal customer={client} key={"logsCustomer"} />
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <SubmitButton
          isLoading={isLoading}
          text={"Save"}
          textLoading="Saving..."
          key={"saveEmployee"}
        />
      </div>
    </form>
  );
};

export default ClientEdit;
