import React, { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserByUserAndPassword } from "../../store/thunks/userThunk";
import { useNotifications } from "../../context/Notifications";
import Alerts from "../alerts/Alerts";
import SubmitButton from "../submitButton/SubmitButton";
import { setResetPassword } from "../../store/slices/userSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordisVisible, setVisible] = useState(false);
  const passwordVisibility = passwordisVisible ? "text" : "password";
  const { isLogged, isLoading, resetPassword } = useSelector(
    (state) => state.user
  );
  const { notifications, removeNotification, addNotification } =
    useNotifications();

  const handleRemoveAlert = (id) => {
    removeNotification(id);
  };

  const [userData, setUser] = useState({
    username: "",
    password: "",
  });

  const onSubmitUser = async (event) => {
    event.preventDefault();
    dispatch(
      getUserByUserAndPassword(
        userData.username,
        userData.password,
        addNotification
      )
    );
  };

  const onChangeUser = (event, name) => {
    const value = event.target.value;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const goToDashboard = () => {
    return navigate(`/`);
  };

  const goChangePassword = () => {
    return navigate(`/changePassword`);
  };

  const resetValues = () => {
    dispatch(setResetPassword(false));
  };

  useEffect(() => {
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged && !isLoading) {
      goToDashboard();
    }

    if (resetPassword) {
      goChangePassword();
    }
  });

  return (
    <div className="flex justify-center  items-center h-screen bg-white dark:bg-gray-800">
      {notifications.map((noti) => {
        return (
          <Alerts
            key={noti.id}
            id={noti.id}
            kind={noti.kind}
            text={noti.message}
            handleRemoveAlert={handleRemoveAlert}
          />
        );
      })}
      <form
        method="GET"
        className="max-w-sm mx-auto w-fit md:w-full lg:w-full"
        onSubmit={onSubmitUser}
      >
        <div className="flex justify-center mb-10 ">
          <img src="logo-OB.svg" className="h-32" alt="Gibt Logo" />
        </div>
        <div className="mb-5 ">
          <label
            htmlFor="username"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your username
          </label>
          <input
            type="username"
            id="username"
            name="username"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Username"
            value={userData.username}
            onChange={(e) => onChangeUser(e, "username")}
            required
          />
        </div>
        <div className="mb-5">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your password
          </label>
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <input
              type={passwordVisibility}
              id="password"
              className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Password"
              value={userData.password}
              onChange={(e) => onChangeUser(e, "password")}
              required
            />
            <div
              className="p-2 cursor-pointer text-black dark:text-white"
              onClick={() => setVisible(!passwordisVisible)}
            >
              {passwordisVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            className="dark:text-white text-sm md:text-base lg:text-lg"
            onClick={() => navigate("/enterMail")}
          >
            Forgot Password?
          </button>
          <SubmitButton
            isLoading={isLoading}
            textLoading="Login..."
            text={"Log in"}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
