import React, { useState } from "react";
import SubmitButton from "../../components/submitButton/SubmitButton";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../services/email/services";
import { useNotifications } from "../../context/Notifications";
import Alerts from "../../components/alerts/Alerts";
import { envelopeIcon } from "../../utils/historyIcons/historyIcons";

const EnterMail = () => {
  const navigate = useNavigate();
  const { notifications, removeNotification, addNotification } =
    useNotifications();

  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (userEmail) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(userEmail).toLowerCase());
  };

  const onEnterMail = (event) => {
    const value = event.target.value;
    setEmail(value);
    if (!value) {
      setError("Please Enter an Email");
      return;
    }

    if (!validateEmail(value)) {
      setError("Please Enter a Valid Email Address");
      return;
    }
    setError(null);
  };

  const handleRemoveAlert = (id) => {
    removeNotification(id);
  };

  const handleSubmitMail = async (event) => {
    event.preventDefault();

    if (error === null) {
      setIsLoading(true);
      const response = await forgetPassword(userEmail);
      if (response?.error) {
        addNotification({
          id: Date.now(),
          message: "Email doesn`t exists!",
          kind: "danger",
        });
        setIsLoading(false);
      } else {
        setEmail("");
        setIsLoading(false);
        navigate("/changePassword");
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 w-screen h-screen overflow-scroll flex-col items-center content-center">
      {notifications.map((noti) => {
        return (
          <Alerts
            key={noti.id}
            id={noti.id}
            kind={noti.kind}
            text={noti.message}
            handleRemoveAlert={handleRemoveAlert}
          />
        );
      })}
      <div className="flex justify-center">
        {envelopeIcon("100px", "100px")}
      </div>

      <div className="mb-4 flex lg:py-3 justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-wrap mb-3 text-center font-bold text-2xl md:text-3xl lg:text-4xl tracking-tight dark:text-white">
            RESET YOUR PASSWORD
          </h1>
          <h1 className="text-wrap w-80 md:w-96 lg:w-96 tracking-tight dark:text-white text-center">
            Please enter the email of your account to reset your password. A
            mail with a link and a temporal password will be sent to your email
            if its match with an account.
          </h1>
        </div>
      </div>
      <form
        method="POST"
        onSubmit={handleSubmitMail}
        className="flex w-full justify-center items-center"
      >
        <div>
          <div className="flex justify-center mb-6">
            <div className="grid gap-6 mb-6 md:grid-cols-1 justify-items-center md:justify-items-stretch lg:justify-items-stretch md:min-w-96 lg:min-w-96">
              <div className="w-56 md:w-full lg:w-full">
                <label
                  htmlFor="user_Mail"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter Your Email
                </label>
                <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <input
                    type="mail"
                    id="Email"
                    className="bg-gray-50 border border-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email"
                    value={userEmail}
                    onChange={onEnterMail}
                    required
                  />
                </div>
              </div>
              {error && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {error}
                </p>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="flex justify-between">
              <button
                type="button"
                className="dark:text-white"
                onClick={() => navigate("/login")}
              >
                Cancel
              </button>
              <SubmitButton
                isLoading={isLoading}
                textLoading="Sending Mail..."
                text={"Continue"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnterMail;
